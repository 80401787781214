.get-started {
  width: fit-content;
  align-items: center;
  margin-top: 2.5rem !important;
}

.intro-text {
  font-family: "Francois One";
  font-size: clamp(2rem, 5vw, 4rem);
}

.intro-text-small {
  font-size: clamp(1rem, 2.5vw, 1.75rem) !important;
}
.intro-text-container {
  z-index: 2 !important;
}

.intro-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .intro-text-container {
    text-align: center;
  }
  .get-started {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
  }
}

.start-vote-button {
  font-size: 1.2rem !important;
}

.join-vote-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.join-vote-button:hover,
.start-vote-button:hover {
  background-color: #e5e6db !important;
  border-color: #e5e6db !important;
  color: #393e41 !important;
}

.lobby-code-input {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.bg-img {
  background: linear-gradient(#393e41, rgba(20, 20, 20, 0.92),rgba(20, 20, 20, 0.806)),
    url("../../assets/background-official-flipped.jpg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 0 !important;
  background-size: cover;
  background-position: 50%;
}
@media only screen and (max-width: 600px) {
  .bg-img {
    background-repeat: no-repeat !important;
  }
}
