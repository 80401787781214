body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appContainer {
  height: 100vh;
  overflow: auto;
  font-family: "Fjalla One";
  background-color: #393e41;
  color: #e5e6db;
}

.btn-primary {
  color: white !important;
  background-color: #ff753b !important;
  outline-color: #ff753b !important;
  border-color: #ff753b !important;
  text-shadow: #000000 1px 2px 10px !important;
  border-radius: 30px 30px 30px 30px !important;
}

.btn-secondary {
  color: white !important;
  background-color: #540b0e !important;
  outline-color: #540b0e !important;
  border-color: #540b0e !important;
  text-shadow: #000000 1px 2px 3px;
  border-radius: 30px 30px 30px 30px !important;
}

.btn-round {
  border-radius: 30px 30px 30px 30px !important;
}

.btn-danger {
  color: white !important;
  background-color: #231410 !important;
  outline-color: #231410 !important;
  border-color: #231410 !important;
  text-shadow: #000000 1px 2px 3px;
  font-size: 15px !important;
  border-radius: 30px 30px 30px 30px !important;
}

.text-align-center {
  text-align: center !important;
}

.text-shadow {
  text-shadow: #000000 1px 2px 3px;
}

.btn-small {
  padding: 0px 5px 0px 5px !important;
  font-size: 0.7rem !important;
}

.g-card {
  border-radius: 5px;
  background-color: #e5e6db;
  box-shadow: #0000008a 1px 1px 25px;
}

.card-container {
  margin-top: 30px;
  box-shadow: 1px 2px 15px 3px #00000044;
  background: #e5e6db;
  border-radius: 15px;
  
  margin-inline: auto !important;
  color: black !important;
}

.logo {
  height: 200px !important;
  width: 200px !important;
  cursor: pointer;
  z-index: 1;
  background-repeat: no-repeat;
}
