.setup {
  justify-content: center;
  align-items: center;
  color: black !important;
  margin-block: auto;
  height: 100%;
}

@media only screen and (max-width: 300px) {
  .lobby-start-form {
    padding-inline: 5px !important;
  }
  
}
