.movie-pick-list {
  width: 500px;
  opacity: 1;
  border-radius: 0px !important;
}

@media only screen and (max-width: 500px) {
  .movie-pick-list {
    width: 80vw !important;
    opacity: 1;
  }
}

.list-item {
  background-color: #e5e6db !important;
  justify-content: space-between;
}

.toast {
  width: 15rem !important;
  position: fixed;
  bottom: 0;
  z-index: 999;
  left: 0;
  color: #000000 !important;
}

.movie-picks {
  position: absolute;
  z-index: 1;
  right: 2%;
  bottom: 2%;
}

.hide-list,
.show-list {
  width: fit-content;
  position: absolute;
  top: 0px;
  left: -10px;
  text-shadow: 0px!important;
  box-shadow: 0px!important;

}
